@import url('https://rsms.me/inter/inter.css');

:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: #0069d9;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: 0.5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
}

.signature {
  color: green;
  font-size: 18px;
}

.button-clear {
  border: none;
  padding: .6rem 1.5rem;

  border-radius: 10px;
}

.Toastify__toast-theme--light {
  font-size: 1.2rem;
}

.sigCanvas {
  width: 80%;
  height: 300px;
}

.button-save {
  border: none;
  padding: .6rem 1.5rem;
  background-color: #0069d9;
  border-radius: 10px;
  color: white;
  margin-right: .7rem;
  cursor: pointer;
}

.button {
  border: none;
}

.sigCanvas {
  border: 2px solid #ccc;
}

.color-red {
  color: red;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 85%;
  margin: auto;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.white {
  background-color: white;
  box-shadow: 2px 23px 45px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.title {
  color: #0069d9;
}

.input {
  position: relative;
  width: 100%;
}

.input__label {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;

  line-height: 1.2;
  color: #949494;
}

.input__field {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 2px solid #949494;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}

.input__field:not(:-moz-placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.input__field:not(:-ms-input-placeholder)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.input__field:focus+.input__label,
.input__field:not(:placeholder-shown)+.input__label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: var(--color-accent);
  border-color: #0069d9;
}

.card {
  background: var(--color-background);
  padding: calc(4 * var(--size-bezel));
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: 3px solid var(--color-shadow, currentColor);
  box-shadow: 0.5rem 0.5rem 0 var(--color-shadow, currentColor);
}

.card--inverted {
  --color-background: var(--color-dark);
  color: var(--color-light);
  --color-shadow: var(--color-accent);
  border-color: #0069d9;
}

.card--accent {
  --color-background: var(--color-signal);
  --color-accent: var(--color-light);
  border-color: #0069d9;
  /* color: var(--color-dark); */
}

.card *:first-child {
  margin-top: 0;
}

.input__field:focus {
  border-color: #0069d9;
  z-index: 1;
}

.input__field:focus {
  outline: none !important;
  border: 2px solid #0069d9;
  z-index: 1;
}

.p-3-right {
  padding-right: 20px;
  margin-right: 5px;
}

p,
label {
  font-size: 14px;
}

.button {
  background-color: #0069d9;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  font-size: 14px;
}

.button:hover {
  background-color: #0856a8;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #0069d9;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 520px) {
  .sigCanvas {
    width: 100%;
  }
}